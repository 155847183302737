import { customDate } from '../extension/currentDate';
import { database, ref, set, get } from './firebase';

export const addDetailFunction = (type, title, detail) => {
    const databaseRef = ref(database, `detail/${type}/${customDate}/${new Date().getTime()}`);
  
    const data = {
      title: title || "Unknown Title", 
      detail: detail || "No detail",  
      timestring: new Date().toISOString() 
    };
  
    return set(databaseRef, data);
};
  
export const getDetailCount = async (type) => {
  try {
    const databaseRef = ref(database, `detail/${type}`);
    const snapshot = await get(databaseRef);

    if (snapshot.exists()) {
      const data = snapshot.val();
      console.log(Object.keys(data).length);
    } 
  } catch (error) {
    console.error("Error retrieving data:", error);
  }
};

