/** @format */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";
import BioDialog from "./../dialog/BioDialog.js";
import ItemDialog from "./../dialog/ItemDialog.js";

import { generateStars, recordText } from "./../extension/generateStars";

import {
  app_icon2,
  profile_icon,
  food_icon,
  app_icon,
  soci_coin,
  digital_icon,
  item_icon,
} from "./../extension/exportedIcons";
import { copyrightLabel } from "./../extension/exportedStrings";

import { checkProfileExist } from "./../extension/setProfileIcon";
import { ItemImage } from "./../extension/setItemIcon.js";

import { userAuthenticated } from "./../extension/currentUser";

import {
  url_path,
  config,
  retrieveItemsAndDigitals,
} from "./../extension/exportedStrings";

import { cashName, cashPrice, cashAmount } from "./../data/cashArray";
import "./../style/profile.css";
import EditProfileDialog from "../dialog/EditProfileDialog.js";

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname.slice(1));

  const [profilePic, setProfilePic] = useState(profile_icon);
  const [firstButton, setFirstButton] = useState("Edit Profile");
  const [secondButton, setSecondButton] = useState("Add Item");

  const [bioText, setBioText] = useState("");

  const [isEditOpen, setEditOpen] = useState(false);
  const [isBioOpen, setBioOpen] = useState(false);
  const [isItemOpen, setItemOpen] = useState(false);

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState([true]);

  useEffect(() => {
    if (currentPath !== location.pathname.slice(1)) {
      setCurrentPath(location.pathname.slice(1));
      window.location.reload(); // Reload the page
    }
  }, [location]);

  useEffect(() => {
    document.title = `${currentPath} - SociRank`;
    const bioInput = document.getElementById("profileBio");

    async function fetchProfileImage() {
      const imageSource = await checkProfileExist(currentPath);
      setProfilePic(imageSource);
    }

    fetchProfileImage();

    const configUser = () => {
      if (currentPath != userAuthenticated) {
        // check follow status also
        setFirstButton("Follow");
        setSecondButton("Message");
      }

      const url = url_path + config;
      const data = {
        username: currentPath, // Replace with the actual username
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          const bioData = data.bio;
          if (!bioData.trim() == "") {
            setBioText(bioData);
          } else {
            bioInput.style.border = "none";
            bioInput.style.pointerEvents = "none";
          }
        })
        .catch((error) => {
          setCurrentPath("User not found");
        });
    };

    configUser();
    retrieveItems();
  }, []);

  const retrieveItems = () => {
    const url = url_path + retrieveItemsAndDigitals;
    const data = {
      username: currentPath, // Replace with the actual username
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setItems(data);
        setIsLoading(false); 
      })
      .catch((error) => {
        console.error("Error retrieving items:", error);
        setIsLoading(false); 
      });
  };

  const openBio = () => {
    setBioOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const openItem = () => {
    setItemOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const openMessage = () => {
    let path = `/message/${currentPath}`;
    navigate(path);
  };

  const goToItem = (item) => {
    if(item.type === "item"){
      const path = `/item/${item.itemUrl}`;
      navigate(path);
    }else{
      const path = `/digital/${item.itemUrl}`;
      navigate(path);
    }
  };

  const openEdit = () => {
    setEditOpen(true);
    document.body.style.overflowY = "hidden";
  };

  return (
    <div>
      <MinibarComponent />
      <div className="ProfileDiv">
        <img src={profilePic} className="profilePic" id="profilePic" />

        <div className="profileTextDiv">
          <p id="profileUsername" class="profileUsername">
            {currentPath}
          </p>
          <p id="profileStatus" class="profileStatus">
            {recordText[5]}
          </p>
          <p id="profileStar" class="profileStar">
            {generateStars(5)}
          </p>
          <p id="profileBio" class="profileBio" onClick={openBio}>
            {bioText}
          </p>
        </div>
        <img src={food_icon} className="foodPic" id="foodPic" />
      </div>
      <div className="editDiv">
        {userAuthenticated === currentPath && (
          <button
            className="profileButton"
            onClick={() => (userAuthenticated === currentPath ? openEdit() : openMessage())}
          >
            {firstButton}
          </button>
        )}
        <button className="profileButton" onClick={() => userAuthenticated == currentPath ? openItem() : openMessage()}>
          {secondButton}
        </button>

      </div>
      <hr className="shadow-line"></hr>

      {items.length === 0 ? (
        <div className="item-container" id="itemContainer">
          {isLoading ? (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          ) : (
            <p id="profileStatusText" className="profileStatusText">
              User has no items
            </p>
          )}
        </div>
      ) : (
          <div className="profile-item-list">
            {items.map((item, index) => (
                <div className="profileItemView" onClick={() => goToItem(item)}>
                {!isItemOpen && !isBioOpen && !isEditOpen && (<img src={item.type === "item" ? item_icon : digital_icon} className="profileTypeImg" alt="Profile Type" />)}
                <ItemImage timestring={item.timestring} currentPath={currentPath} type={item.type} />
                <div className="profileBottomView"></div>
                <p className="profileItemLabel">{item.itemName}</p>
                <button className="profileItemButton">
                  ${item.itemPrice} 
                </button>
              </div>
            ))}
          </div>
      )}

      <div className="ProfileFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel2">{copyrightLabel}</p>
      </div>

      <EditProfileDialog isOpen={isEditOpen} setIsOpen={setEditOpen} profilePic={profilePic} setProfilePic={setProfilePic} items={items} setItems={setItems} setBioText={setBioText}/>
      <BioDialog text={bioText} isOpen={isBioOpen} setIsOpen={setBioOpen} />
      <ItemDialog isOpen={isItemOpen} setIsOpen={setItemOpen} />
      
    </div>
  );
};

export default Profile;
