const { url_path, getCountry } = require("../../extension/exportedStrings");

export const getLocationCountry = async () => {
  try {
    const url = url_path + getCountry;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    const country = data.country || ""; // Country or empty string if not found

    // Store the country data and expiration time in localStorage
    const expirationTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
    localStorage.setItem(
      "country",
      JSON.stringify({ country, expiration: expirationTime })
    );

    return country;
  } catch (error) {
    // If there's an error, return empty string
    return "";
  }
};

// Helper function to get country from localStorage, checking expiration
export const getStoredCountry = () => {
  const storedData = localStorage.getItem("country");
  if (!storedData) return ""; // No country data in localStorage

  const { country, expiration } = JSON.parse(storedData);

  // Check if the stored data is expired (after 30 days)
  if (new Date().getTime() > expiration) {
    localStorage.removeItem("country"); // Remove expired data
    return ""; // Return empty if expired
  }

  return country; // Return the stored country if it's still valid
};
