/** @format */

export function convertPriceToStripe(priceWithSymbol, currencyCode) {
  // Remove non-numeric characters except for the decimal point
  const priceWithoutSymbol = priceWithSymbol.replace(/[^\d.]/g, "");

  // Convert to float
  let priceFloat = parseFloat(priceWithoutSymbol);
  if (isNaN(priceFloat)) return 0; // Handle invalid input

  // Currencies that do NOT use cents
  const noCentsCurrencies = ["BIF", "CLP", "DJF", "GNF", "JPY", "KMF", "KRW", "MGA", "PYG", "RWF", "UGX", "VND", "VUV", "XAF", "XOF", "XPF"];

  // Multiply by 100 only if currency uses cents
  const stripePrice = noCentsCurrencies.includes(currencyCode.toUpperCase()) ? priceFloat : priceFloat * 100;

  return Math.round(stripePrice); // Stripe requires an integer
}

export function convertAmountToCurrency(priceWithSymbol) {
  // Remove any non-numeric characters except for the decimal point
  const priceWithoutSymbol = priceWithSymbol.replace(/[^\d.]/g, "");
  // Return the result
  return priceWithoutSymbol;
}
// Example usage:
// const priceInCents = convertPriceToCents('$5');