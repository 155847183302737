import axios from 'axios';
import { exchangeRateAPI } from '../exportedStrings';
import { convertAmountToCurrency } from '../stripeConverter';

export const getExchangeRate = async (fromCurrency, toCurrency) => {
  const apiKey = exchangeRateAPI;  // Replace with your ExchangeRate-API key
  const url = `https://v6.exchangerate-api.com/v6/${apiKey}/latest/${fromCurrency}`;

  try {
    const response = await axios.get(url);

    // Check if the conversion_rates object exists
    if (response.data && response.data.conversion_rates) {
      const rate = response.data.conversion_rates[toCurrency];

      if (rate) {
        localStorage.setItem(`exchange_rate`, rate);
      }
    }
  } catch (error) {
    console.error("Error fetching exchange rate:", error);
  }
};

export const convertCurrency = (amount) => {
  let new_amount = convertAmountToCurrency(amount);

  const localStorageKey = `exchange_rate`;
  let rate = localStorage.getItem(localStorageKey) || "";

  // Check if rate is empty or not a valid number
  if (rate.trim() === "" || isNaN(rate)) {
    return parseFloat(amount).toFixed(2);  // Return the original amount with 2 decimal places
  }

  // Ensure proper conversion: USD to THB (or another conversion rate)
  let convertedAmount = new_amount * (1 / parseFloat(rate));  // Inverse of the rate if needed
  
  // Limit to 2 decimal places and return as a number (not string)
  return parseFloat(convertedAmount.toFixed(2));
};


