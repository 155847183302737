import { formatMoneyToDecimal } from "../../../extension/moneyConvert";
import { convertCurrency } from "../../../extension/stripe_currency/stripeCurrency";
import { getCurrencySymbolPosition } from "../../../extension/stripe_currency/stripeCurrencySymbol";

export const getFormattedPrice = (amount, setPriceSelected) => {
  const symbol = localStorage.getItem("symbol") || "$"; // Custom symbol stored

  try {
    const convertedAmount = convertCurrency(amount);
    const decimalAmount = formatMoneyToDecimal(convertedAmount);
    const position = getCurrencySymbolPosition();

    if(decimalAmount == '0') return '$' + amount;

    if(position == 1){
      return symbol + decimalAmount;
    }else{
      return decimalAmount + symbol;
    }

  } catch (error) {
    setPriceSelected();
    return '$' + amount;
  }
};
  