import { getStoredCountry } from "../../data/location/getCountry";

  export const getCurrencySymbol = async () => {
    const storedCountry = getStoredCountry(); // Ensure this variable holds the correct country name
    const url = `https://restcountries.com/v3.1/name/${storedCountry}`;

    try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        
        const data = await response.json();
        if (data && data[0] && data[0].currencies) {
            const currencyCode = Object.keys(data[0].currencies)[0]; // Get first currency code
            const symbol = data[0].currencies[currencyCode]?.symbol;

            if (symbol) {
                localStorage.setItem('symbol', symbol);
                localStorage.setItem('currencyCode', currencyCode);

            } else {
                console.error('Currency symbol not found');
            }
        } else {
            console.error('Currency information not found');
        }
    } catch (error) {
        console.error('Error fetching country data:', error);
    }
  };

  export const formatAmountWithSymbol = (amount) => {
    const symbol = localStorage.getItem("symbol"); // Custom symbol stored
    const currencyCode = localStorage.getItem("currencyCode");
  
    if (currencyCode) {
      const formattedAmount = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currencyCode,
        currencyDisplay: "symbol", // Forces symbol instead of code
      }).format(amount);
  
      return formattedAmount.replace(currencyCode, symbol);
    } 
  };

  export const getCurrencySymbolPosition = () => {
    const currencyCode = localStorage.getItem("currencyCode");
  
    if (!currencyCode) return 1; // Default to 1 if no currency code is set
  
    // Format a test amount (1) to see where the symbol appears
    const formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
      currencyDisplay: "symbol",
    }).format(1);
  
    return formatted.startsWith("¤") || formatted.startsWith(currencyCode) ? 1 : 2;
    };
  
  
  
  