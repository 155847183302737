/** @format */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { url_path, check, register, copyrightLabel } from "./../extension/exportedStrings";
import { validateEmail, validateUsername } from "./../extension/inputValidity";
import { createAcc } from "./../extension/registerAccount";

import { app_icon, app_icon2 } from "./../extension/exportedIcons";
import FooterComponent from "./Footer.js";

import "./../style/register.css";
import { addErrorFunction } from "../firebase/errorCheckFirebase.js";
import { addDetailFunction } from "../firebase/detailCheckFirebase.js";
const Register = () => {
  useEffect(() => {
    document.title = "SociRank - Register"; // Change the title here

    addDetailFunction("website", "visited", "web was visited");
  }, []);
  
  const navigate = useNavigate();

  const [button, setButton] = useState("Register");
  const [errorMessage, setErrorMessage] = useState("");

  const [validArray, setValidArray] = useState([false, false, false, false, false]);

  const [usernameValue, setUsernameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [dobValue, setDOBValue] = useState("");
  const [fullnameValue, setFullname] = useState("");

  const detailCheck = async (value, type) => {
    setErrorMessage(type === "username" ? "Checking username availability..." : "Checking email availability...");
  
    const isValid = type === "username" ? validateUsername(value) : validateEmail(value);
    
    if (!isValid) {
      const item = document.getElementById(type);
      item.style.borderColor = "red";
      setErrorMessage(type === "username" ? "Username must be valid, without spaces, and under 24 characters." : "Email is not valid");
      return;
    }
  
    const url = url_path + check;
    const data = { username: value, type: type };
  
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
  
      if (!res.ok) throw new Error(`HTTP error! Status: ${res.status}`);
  
      const responseData = await res.json();
      const item = document.getElementById(type);
  
      if (responseData === "success") {
        item.style.borderColor = "#AFE1AF";
  
        setValidArray((prevValidArray) => {
          const updatedValidArray = [...prevValidArray];
          updatedValidArray[type === "username" ? 0 : 1] = true;
          return updatedValidArray;
        });
  
        if (type === "username") {
          setUsernameValue(value.trim());
          setErrorMessage("Username is available");
        } else {
          setEmailValue(value.trim());
          setErrorMessage("Email is available");
        }
      } else {
        item.style.borderColor = "red";
  
        setValidArray((prevValidArray) => {
          const updatedValidArray = [...prevValidArray];
          updatedValidArray[type === "username" ? 0 : 1] = false;
          return updatedValidArray;
        });
  
        setErrorMessage(type === "username" ? "Username already exists" : "Email already exists");
        addErrorFunction("register_detail", type === "username" ? "username exists" : "email exists", url, type === "username" ? usernameValue : emailValue, data);
      }
    } catch (error) {
      addErrorFunction("register_detail", "checking for username/email error", url, usernameValue, error);
      setErrorMessage("Error checking for username");
    }
  };
  
  const fullnameCheck = async (value) => {
    const item = document.getElementById("fullname");
    const trimmedValue = value.trim();
  
    if (trimmedValue === "") {
      item.style.borderColor = "red";
      setValidArray((prevValidArray) => {
        const updatedValidArray = [...prevValidArray];
        updatedValidArray[4] = false;
        return updatedValidArray;
      });
    } else {
      item.style.borderColor = "#AFE1AF";
      setFullname(trimmedValue);
      setValidArray((prevValidArray) => {
        const updatedValidArray = [...prevValidArray];
        updatedValidArray[4] = true;
        return updatedValidArray;
      });
    }
  };

  const passwordCheck = async (value) => {
    const item = document.getElementById("password");
  
    if (value.length < 8) {
      item.style.borderColor = "red";
  
      setValidArray((prevValidArray) => {
        const updatedValidArray = [...prevValidArray];
        updatedValidArray[2] = false;
        return updatedValidArray;
      });
  
    } else {
      item.style.borderColor = "#AFE1AF";
      setPasswordValue(value);
  
      setValidArray((prevValidArray) => {
        const updatedValidArray = [...prevValidArray];
        updatedValidArray[2] = true;
        return updatedValidArray;
      });
    }
  };

  const dobCheck = async () => {
    const dob1 = document.getElementById("dob1");
    const dob2 = document.getElementById("dob2");
    const dob3 = document.getElementById("dob3");
  
    if (dob1.value.trim() !== "" && dob2.value.trim() !== "" && dob3.value.trim() !== "") {
      dob1.style.borderColor = "#AFE1AF";
      dob2.style.borderColor = "#AFE1AF";
      dob3.style.borderColor = "#AFE1AF";
  
      setValidArray((prevValidArray) => {
        const updatedValidArray = [...prevValidArray];
        updatedValidArray[3] = true;
        return updatedValidArray;
      });
  
      setDOBValue(`${dob1.value.trim()}/${dob2.value.trim()}/${dob3.value.trim()}`);
    } else {
      setValidArray((prevValidArray) => {
        const updatedValidArray = [...prevValidArray];
        updatedValidArray[3] = false;
        return updatedValidArray;
      });
  
      if (dob1.value.trim() === "") dob1.style.borderColor = "red";
      if (dob2.value.trim() === "") dob2.style.borderColor = "red";
      if (dob3.value.trim() === "") dob3.style.borderColor = "red";
    }
  };
  

  const createAccount = async () => {
    if(button === "Creating account...") return;
    
    if (validArray.every(Boolean)) {
       setButton("Creating account...")
       
       const url = url_path + register; 

       const success = await createAcc(url, usernameValue, fullnameValue, emailValue, passwordValue, dobValue);

       if (success) {
        const authenticationData = {
          username: String(usernameValue).toLowerCase(),
        };
  
        // Save the data to localStorage
        localStorage.setItem('currentUser', JSON.stringify(authenticationData));
  
        let path = `/`;
        navigate(path);
        window.location.reload();
      } else {
        setButton("Try again");
        addErrorFunction("register_creation", "failed to create account", url, usernameValue, success);
      }
    }
  }

  return (
    <div>
      <div className="registerBackground">
        <div className="divBox">
          <img src={app_icon2} className="divImage" width="25" height="25" />
          <input
            className="username"
            id="username"
            name="username"
            placeholder="Enter username"
            onBlur={(e) => detailCheck(e.target.value, "username")}
            autoFocus
          ></input>
          <input
            className="fullname"
            id="fullname"
            name="fullname"
            placeholder="Enter fullname"
            onBlur={(e) => fullnameCheck(e.target.value)}
          ></input>
          <input
            className="email"
            id="email"
            type="email"
            name="email"
            placeholder="Enter email"
            onBlur={(e) => detailCheck(e.target.value, "email")}
          ></input>
          <input
            id="password"
            class="password"
            name="password"
            type="password"
            placeholder="Enter password"
            onBlur={(e) => passwordCheck(e.target.value)}
          />

          <div id="date-input" className="date-input" onBlur={(e) => dobCheck()}>
            <input id="dob1" type="number" placeholder="Day" min="1" max="31" />
            <input id="dob2" type="number" placeholder="Month" min="1" max="12" />
            <input id="dob3" type="number" placeholder="Year" min="1900" max="2099" />
          </div>

          <p id="tosLabel" class="regtosLabel">
            By clicking register, you agree that you have read and accepted the
            <a href="/terms-condition" className="link" target="_blank">
              {" "}
              Terms of Use
            </a>
          </p>

          <button id="registerButton" class="regButton" onClick={createAccount}>
            {button}
          </button>
          <p class="registerUsernameError">{errorMessage}</p>
        </div>
        <div className="loginFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel">
            {copyrightLabel}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
