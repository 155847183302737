/** @format */
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import "./../style/login.css";
import "./../style/footer.css";

import {
  app_icon,
  app_icon2,
  apple_icon,
  google_icon,
  google_icon2
} from "./../extension/exportedIcons";

import {
  copyrightLabel,
} from "./../extension/exportedStrings";

import FooterComponent from "./../design/Footer.js";
import { userAuthenticated } from "../extension/currentUser.js";
import { addDetailFunction } from "../firebase/detailCheckFirebase.js";

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "SociRank - Shop"; // Change the title here

    if (!userAuthenticated) {
       navigate("/login");
    }

    addDetailFunction("website", "visited", "web was visited");
    
  }, []);

  return (
    <div>
      <div className="loginBody">
        <div className="introBox">
          <img src={app_icon} className="introImage" width="25" height="25" />
          <h1 className="introLabel">SociRank</h1>
          <p className="introExplain">
          Showcase your talents, earn in-app currency, and engage in a reel-like marketplace where you can sell, purchase, and thrive in a competitive social environment.
          </p>

          <a
            href="https://play.google.com/store/apps/details?id=com.socirank"
            target="_blank"
          >
            <img
              src={google_icon}
              className="google_promo"
              alt="Google promo"
            />
          </a>

          <a
            href="https://apps.apple.com/us/app/socirank/id1628275604"
            target="_blank"
          >
            <img src={apple_icon} className="apple_promo" alt="Apple promo" />
          </a>
            <button className="homeButtonAvailability">
                Website is currently only available on desktop.
            </button>
        </div>
      </div>
      <div className="loginFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel">
            {copyrightLabel}
          </p>
      </div>
    </div>
  );
};

export default HomePage;
