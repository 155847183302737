/** @format */
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";

import { app_icon, soci_coin } from "./../extension/exportedIcons";
import { top_icon, hoodie_icon, pants_icon } from "./../extension/merchImg";

import { getCoinValue } from "./../data/coinData";
import { cashName, cashPrice, cashAmount } from "./../data/cashArray";
import { copyrightLabel, retrieveAllitemsAndDigitals, url_path } from "./../extension/exportedStrings";

import "./../style/home.css";
import HomeImage from "../extension/setHomeItemicon.js";
import { calculateSociCoin } from "../extension/socicoin_calculation.js";
import { displayCategoryArray } from "../data/categoryArray.js";
import { getLocationCountry, getStoredCountry } from "../data/location/getCountry.js";
import { getCurrencyFromCountry } from "../data/location/getCountryToCurrency.js";
import { getExchangeRate } from "../extension/stripe_currency/stripeCurrency.js";
import { formatMoney, formatMoneyToDecimal } from "../extension/moneyConvert.js";
import { getCurrencySymbol } from "../extension/stripe_currency/stripeCurrencySymbol.js";
import { addDetailFunction } from "../firebase/detailCheckFirebase.js";
const Home = () => {
  const navigate = useNavigate();
  
  const [coinValue, setCoinValue] = useState("0");
  const [items, setItems] = useState([]);

  useEffect(() => {
    document.title = "SociRank - Store"; // Change the title here
    
    getCoinValue.then(result => {
      if (!isNaN(result) && result.trim() !== '') {
        localStorage.setItem('balance', parseFloat(result).toString());
      } else {
        localStorage.setItem('balance', 0);
      }
      
      if (!isNaN(result) && Number.isInteger(Number(result))) {
        setCoinValue(result);
      } 
    });

    if(getStoredCountry() == ""){
      getLocationCountry()
      .then(country => {
        getExchangeRate(getCurrencyFromCountry(country), "USD");
        getCurrencySymbol();
      })
      .catch(error => {
          console.error("Error fetching country:", error); // Handle any errors here
      });
    }

    retrieveItemsAndDigital();
    addDetailFunction("website", "visited", "web was visited");

  }, []);

  const goExchange = (index) => {
    let path = `/exchange/${index}`;
    navigate(path);
  }

  const goToItem = (item) => {
    if(item.type === "item"){
      const path = `/item/${item.itemUrl}`;
      navigate(path);
    }else{
      const path = `/d igital/${item.itemUrl}`;
      navigate(path);
    }
  }

  const goCategory = (item) => {
    const path = `/category/${item.replace(/\s+/g, '')}`;
    navigate(path);
  }

  const retrieveItemsAndDigital = () => {
    const url = url_path + retrieveAllitemsAndDigitals;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setItems(data);
      })
      .catch((error) => {
        console.error("Error retrieving items:", error);
      });
 
  };

  return (
    <div>
        <MinibarComponent />
        <div className="homeMenuDiv">
            {displayCategoryArray.map((item, index) => (
              <div className="homeMenuListDiv" onClick={() => goCategory(item)}>
              <p className="homeMenuText">{item}</p>
              </div>
            ))}        
        </div>
        <hr className="lineView2" />

        <div className="coinDiv">
          <img src={soci_coin} className="coinImg" width="30" height="30" />
          <p className="coinLabel">{coinValue}.00</p>
        </div>
        <div className="merchDiv">
          <div className="merchItem">
           <p className="merchLabel">SociTop</p>
            <img src={top_icon} className="merchImg" />
            <div className="merchView"></div>
            <p className="merchLabel2">$5.00 or 5000 SociCoin</p>
            <button className="merchButton">Coming soon</button>
          </div>
          <div className="merchItem">
           <p className="merchLabel">SociHoodie</p>
            <img src={hoodie_icon} className="merchImg" />
            <div className="merchView"></div>
            <p className="merchLabel2">$15.00 or 15000 SociCoin</p>
            <button className="merchButton">Coming soon</button>
          </div>
        </div>
        {items?.length > 0 && (
          <>
            <p className="cashTitle">Featured</p>
            <div className="exchange-container" id="cashContainer">
              {items.map((array, int) => (
                <div className="homeItemtable" onClick={() => goToItem(array)} key={int}>
                  <HomeImage timestring={array.timestring} currentPath={array.username} type={array.type} />
                  <div className="homeItemView"></div>

                  <div className="priceDiv">
                    <img
                      src={soci_coin}
                      className="coinImg2"
                      width="30"
                      height="30"
                    />
                    <p className="cashLabel2">{formatMoneyToDecimal(calculateSociCoin(array.itemPrice))}</p>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
  
        <p className="cashTitle">Exchange SociCoins for Real-Life currency</p>

        <div className="cash-container" id="cashContainer">
            {cashName.map((array, index) => (
              <div className="cashTable" onClick={() => goExchange(index)}>
                <p className="cashLabel">{cashAmount[index]}</p>
                <img src={array} className="cashImg" alt="Image Description" />
                <div className="cashView"></div>

                <div className="priceDiv">
                  <img src={soci_coin} className="coinImg2" width="30" height="30" />
                  <p className="cashLabel2">{cashPrice[index]}</p>
                </div>
              </div>
            ))}
          </div>

        <div className="homeFooterDiv">
          <div className="footerView">
            <img
              className="footerImg"
              src={app_icon}
              alt="Image"
            />
            <FooterComponent />
           </div>

          <p className="copyrightLabel2">
            {copyrightLabel}
          </p>
        </div>
    </div>
  );
};

export default Home;
