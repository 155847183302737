import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import MinibarComponent from "./../Minibar.js";
import FooterComponent from "./../Footer.js";

import {
  userAuthenticated,
  getBalance,
  updateBalance,
} from "./../../extension/currentUser";

import { ItemBuyImage } from "./../../extension/setItemBuyIcon.js";
import { fetchImages } from "./../../extension/fetchItemImages.js";
import { calculateAverageRating } from "./../../extension/calculateAverageRating.js";

import BuyDialog from "./../../dialog/BuyDialog.js";

import CommentDialog from "../../dialog/itemCommentDialog.js";
import ItemBuyDialog from "./../../dialog/ItemDialog.js";
import QuantityDropdown from "./../../dropdown/QuantityDropdown.js";

import {
  app_icon,
  soci_coin,
  delivery_icon,
  return_icon,
} from "./../../extension/exportedIcons";

import { cartArray } from "./../../data/cartArray";
import { configCart, addToCart } from "./functions/cartFunctions.js";

import {
  url_path,
  retrieveOneItem,
  retrieveComment,
  copyrightLabel,
  paymentIntentPath,
  stripePublishableKey,
  createOrder,
  purchaseWithCoins,
} from "./../../extension/exportedStrings";

import "./../../style/item-page.css";
import { addToNotification } from "../../data/notificationData.js";
import BuyChoiceDialog from "../../dialog/BuyChoiceDialog.js";
import { calculateSociCoin } from "../../extension/socicoin_calculation.js";
import { convertPriceToStripe } from "../../extension/stripeConverter.js";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { fetchAddress } from "../../data/retrieveAddress.js";

import { generateUUID } from "../../extension/generateUUID.js";
import CheckoutFormBuy from "../CheckoutFormBuy.js";
import {
  insertBuyToNotification,
  updateBuyOrderStatus,
} from "../../data/reusuableBuyData.js";
import { parseAndFilter } from "../../extension/jsonStringToArray.js";
import ItemDropdown from "../../dropdown/ItemDropdown.js";
import { getFormattedPrice } from "./functions/coinLabelData.js";
import { getStoredCurrency } from "../../data/location/getCountryToCurrency.js";

function ItemPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [items, setItems] = useState([]);
  const [price, setPrice] = useState(0);
  const [imageSrcs, setImageSrcs] = useState([]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const [colorQuantity, setColorQuantity] = useState(1);
  const [materialQuantity, setMaterialQuantity] = useState(1);
  const [modelQuantity, setModelQuantity] = useState(1);
  const [otherQuantity, setOtherQuantity] = useState(1);

  const [colorsArray, setColorsArray] = useState([]);
  const [materialsArray, setMaterialsArray] = useState([]);
  const [modelsArray, setModelsArray] = useState([]);
  const [othersArray, setOthersArray] = useState([]);

  const [colorSelected, setColorSelected] = useState("");
  const [materialSelected, setMaterialSelected] = useState("");
  const [modelSelected, setModelSelected] = useState("");
  const [otherSelected, setOtherSelected] = useState("");

  const [comments, setComments] = useState([]);
  const [commentRating, setCommentRating] = useState("");
  const [cartString, setCartString] = useState("Add To Cart");
  const [isOpen, setIsOpen] = useState(false);
  const [isBuyOpen, setBuyOpen] = useState(false);
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState(false);

  const [convertedSociCoin, setConvertedSociCoin] = useState(0);
  const [priceSelected, setPriceSelected] = useState("");

  const stripePromise = loadStripe(stripePublishableKey);
  const [clientSecret, setClientSecret] = useState("");

  const [deliveryAddress, setDeliveryAddress] = useState("");

  const [currentPath, setCurrentPath] = useState(
    location.pathname.split("/").pop()
  );

  const [isLoading, setIsLoading] = useState([true]);
  const unique_id = generateUUID();

  const [orderString, setOrderString] = useState("Order");
  const [isProcessing, setIsProcessing] = useState(false);

  const balance = getBalance();

  useEffect(() => {
    const updateAddress = async () => {
      const address = await fetchAddress();
      if (address === "Enter your address") {
        setDeliveryAddress("No Address Set"); // Set a default message if no address is found
      } else {
        setDeliveryAddress(address); // Set the fetched address
      }
    };

    const retrieveItems = () => {
      const url = url_path + retrieveOneItem;
      const data = {
        id: currentPath, // Replace with the actual username
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then(async (data) => {
          setItems(data);
          const images = await fetchImages(
            data[0].timestring,
            data[0].username,
            data[0].type
          );
          
          setExtrasQuantity(data[0]);

          setPrice(getFormattedPrice(data[0].itemPrice));
          convertCoins(data[0].itemPrice);

          setImageSrcs(images);
          configCart(data[0], setCartString, cartArray);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error retrieving items:", error);
        });
    };

    const setExtrasQuantity = (data) => {
      const colorsDataArray = parseAndFilter(data.colors);
      const materialsDataArray = parseAndFilter(data.materials)
      const modelsDataArray = parseAndFilter(data.models);
      const othersDataArray = parseAndFilter(data.others);

      setColorsArray(colorsDataArray);
      setMaterialsArray(materialsDataArray);
      setModelsArray(modelsDataArray);
      setOthersArray(othersDataArray);

      const getFirstOrDefault = (array, defaultValue) => (array.length > 0 ? array[0] : defaultValue);

      setColorSelected(getFirstOrDefault(colorsDataArray, ""));
      setMaterialSelected(getFirstOrDefault(materialsDataArray, ""));
      setModelSelected(getFirstOrDefault(modelsDataArray, ""));
      setOtherSelected(getFirstOrDefault(othersDataArray, ""));
    }

    const retrieveComments = () => {
      const url = url_path + retrieveComment;
      const data = {
        itemUrl: currentPath, // Replace with the actual username
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then(async (data) => {
          const ratings = data.map((item) => parseFloat(item.rating));

          // Calculate the average rating
          const averageRating = calculateAverageRating(ratings);
          setCommentRating(averageRating);
          setComments(data);
        })
        .catch((error) => {
          console.error("Error retrieving items:", error);
        });
    };

    retrieveItems();
    retrieveComments();
    updateAddress();
  }, []);

  const convertCoins = (priceData) => {
    setConvertedSociCoin(calculateSociCoin(priceData));
  };

  const openDialog = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const openBuyDialog = () => {
    setBuyOpen(true);
    document.body.style.overflow = "hidden";
  };

  const openCommentDialog = () => {
    setIsCommentOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleArrowRightClick = () => {
    const nextIndex =
      currentImageIndex === imageSrcs.length - 1 ? 0 : currentImageIndex + 1;
    setCurrentImageIndex(nextIndex);
  };

  const handleArrowLeftClick = () => {
    const prevIndex =
      currentImageIndex === 0 ? imageSrcs.length - 1 : currentImageIndex - 1;
    setCurrentImageIndex(prevIndex);
  };

  const goMessage = (value) => {
    let path = `/message/${value}`;
    navigate(path);
  };

  const createPayment = () => {
    const url = url_path + paymentIntentPath;

    const currencyCode = getStoredCurrency();
    const updatedPrice = convertPriceToStripe(price, currencyCode);
    
    setPriceSelected(price);

    const data = {
      price: updatedPrice, 
      currency: currencyCode,
    };
    
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then(async (result) => {
      var { clientToken } = await result.json();
      setClientSecret(clientToken);
      openCardDialog();

      setOrderString("Order");
      setIsProcessing(false);
    }).catch((error) => {
      setOrderString("Order");
      setIsProcessing(false);
    })
  };

  const openCardDialog = () => {
    setIsCardOpen(true);
    document.body.style.overflow = "hidden";
  };

  const itemData = (type) => {
    if (deliveryAddress === "No Address Set" || deliveryAddress.trim() === "") {
      changeAddress();
      return;
    }

    setOrderString("Processing...");
    setIsProcessing(true);

    const data = [];

    data.push({
      unique_id: unique_id,
      username: items[0].username,
      purchased_by: userAuthenticated,
      order_id: items[0].itemUrl,
      date: Date.now(), // Current timestamp
      price: price, // Multiply price by quantity
      address: deliveryAddress,
      quantity: selectedQuantity,
      tracking: "-",
      delivery: "-",
      timestring: items[0].timestring,
      itemName: items[0].itemName,
      color: colorSelected,
      material: materialSelected,
      model: modelSelected,
      other: otherSelected,
    });

    confirmOrder(data, type);
  };

  const purchaseWithSociCoin = () => {
    const url = url_path + purchaseWithCoins;

    const data = {
      username: userAuthenticated,
      price: convertedSociCoin,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then(async (data) => {
        await Promise.all([
          updateBuyOrderStatus(unique_id),
          insertBuyToNotification(items),
        ]);

        setOrderString("Purchased");
        setIsProcessing(false);

        const newBalance = parseFloat(localStorage.getItem("balance")) - convertedSociCoin;
        updateBalance(newBalance);

        goHome();
      })
      .catch((error) => {
        setOrderString("Order");
        setIsProcessing(false);
      });
  };

  const confirmOrder = (orders, type) => {
    const url = url_path + createOrder;

    const data = {
      orders: orders,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        if (type == 1) {
          createPayment();
        } else {
          purchaseWithSociCoin();
        }
      })
      .catch((error) => {
        console.log(error);
        setOrderString("Order");
        setIsProcessing(false);
      });
  };

  const changeAddress = () => {
    const path = `/update-address`;
    navigate(path);
  };

  const goHome = () => {
    const path = `/`;
    window.location.href = path;
  };

  return (
    <div>
      <MinibarComponent />
      {isLoading ? (
        <div className="spinner-container-page">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="itemBuyDiv">
          <div className="leftItemBuyDiv">
            <div className="buyImgContainer">
              <svg
                className="bArrowLeft"
                viewBox="0 0 16 16"
                onClick={handleArrowLeftClick}
                focusable="false"
              >
                <path
                  fill="#5A5A5A"
                  d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                />
              </svg>

              {items && items[0] && (
                <img
                  src={imageSrcs[currentImageIndex]}
                  className={`buyImg`}
                  onClick={openDialog}
                />
              )}

              <svg
                className="bArrowRight"
                viewBox="0 0 16 16"
                onClick={handleArrowRightClick}
                focusable="false"
              >
                <path
                  fill="#5A5A5A"
                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                />
              </svg>
            </div>
            <div className="buy-container" id="gridContainer">
              {imageSrcs.map((image, index) => (
                <div
                  className={`buy-item2 ${
                    index === currentImageIndex ? "selectedItem" : ""
                  }`}
                  key={index}
                  onClick={openDialog}
                >
                  <img
                    className="subImage"
                    src={image}
                    alt={`Image ${index + 1}`}
                  />
                </div>
              ))}
            </div>

            {comments == null || comments.length === 0 ? (
              <p className="ratingName">This listing has no ratings yet</p>
            ) : (
              <>
                <p className="ratingName">Average ratings: {commentRating}</p>
                <div className="ratingDiv">
                  <div className="ratingLabelsDiv">
                    <p className="ratingLeftLabel">Top comments</p>
                    <p className="ratingRightLabel" onClick={openCommentDialog}>
                      View all
                    </p>
                  </div>
                </div>
                <table className="ratingTable">
                  {comments.slice(0, 3).map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="ratingLine">
                          <p className="buytableUsername">{item.username}</p>
                          <p className="buytableRating">{item.rating} ★</p>
                        </div>
                        <p className="buytableAdditional">{item.comment}</p>
                      </td>
                    </tr>
                  ))}
                </table>
              </>
            )}
          </div>
          <div className="rightItemBuyDiv">
            <p className="buyLabel">Name</p>
            <p className="buyName">{items[0].itemName}</p>
            <p className="buyLabel">Price</p>
            <div className="buyPriceDiv">
              <p className="buyPrice">{price}</p>
              <p className="buyOr">or</p>
              <img className="buyIconSociCoin" src={soci_coin} alt="Image" />
              <p className="buyPriceSociCoin"> {convertedSociCoin}</p>
            </div>
            <p className="buyLabel">Description</p>
            <div className="buyDescriptionDiv">
              <textarea class="buyDescriptionText" readOnly>
                {items[0].itemDescription}
              </textarea>
            </div>
            <p className="buyLabel">Category</p>
            <p className="buyName">{items[0].itemCategory}</p>
            {materialsArray.length > 0 && (
              <>
                <p className="buyLabel">Material</p>
                <ItemDropdown
                  cName={"buyQuantity"}
                  selected={materialQuantity}
                  setSelected={setMaterialQuantity}
                  setSelectedValue={setMaterialSelected}
                  array={materialsArray}
                />
              </>
            )}
             {colorsArray.length > 0 && (
              <>
                <p className="buyLabel">Colors</p>
                <ItemDropdown
                  cName={"buyQuantity"}
                  selected={colorQuantity}
                  setSelected={setColorQuantity}
                  setSelectedValue={setColorSelected}
                  array={colorsArray}
                />
              </>
            )}
            {modelsArray.length > 0 && (
              <>
                <p className="buyLabel">Models</p>
                <ItemDropdown
                  cName={"buyQuantity"}
                  selected={modelQuantity}
                  setSelected={setModelQuantity}
                  setSelectedValue={setModelSelected}
                  array={modelsArray}
                />
              </>
            )}
              {othersArray.length > 0 && (
              <>
                <p className="buyLabel">Other</p>
                <ItemDropdown
                  cName={"buyQuantity"}
                  selected={otherQuantity}
                  setSelected={setOtherQuantity}
                  setSelectedValue={setOtherSelected}
                  array={othersArray}
                />
              </>
            )}

            {items[0].username !== userAuthenticated && (
              <>
                <p className="buyLabel">quantity</p>
                <QuantityDropdown
                  cName={"buyQuantity"}
                  selectedQuantity={selectedQuantity}
                  setSelectedQuantity={setSelectedQuantity}
                />
                <div className="buyShopDiv">
                  <button
                    className="buyCart"
                    onClick={() => {
                      if (cartString.toLowerCase() != "added") {
                        addToNotification(
                          items[0],
                          userAuthenticated,
                          userAuthenticated +
                            " has added your item named " +
                            items[0].itemName +
                            " to cart!"
                        );
                      }
                      addToCart(
                        items[0],
                        cartString,
                        setCartString,
                        cartArray,
                        selectedQuantity,
                        colorSelected,
                        materialSelected,
                        modelSelected,
                        otherSelected,
                      );
                    }}
                  >
                    {cartString}
                  </button>
                  <p className="buyOrCart">Or</p>
                  <button
                    className="buyNowCart"
                    onClick={() => {
                      if (isProcessing) return;

                      openBuyDialog();
                    }}
                  >
                    {orderString}
                  </button>
                  <p className="buyLabel">Delivery details</p>
                  <div className="buyDeliveryDiv">
                    <div class="deliveryDiv">
                      <img
                        src={delivery_icon}
                        alt="Image"
                        class="deliveryImg"
                      ></img>
                      <p className="deliveryName">Estimated Delivery</p>
                      <p className="deliveryAdditional">
                        Our website is currently in beta, and some information
                        may not be fully available at this time.
                      </p>
                    </div>
                  </div>
                  {/* <button className="buyMessageButton" onClick={() => {}}>Message Seller</button> */}
                </div>

                <div className="buyDeliveryDiv">
                  <div class="deliveryDiv">
                    <img src={return_icon} alt="Image" class="returnImg"></img>
                    <p className="deliveryName">Return Policy</p>
                    <p className="deliveryAdditional">
                      Our website is currently in beta, and some information may
                      not be fully available at this time.
                    </p>
                  </div>
                </div>
                <button
                  className="buyMessageButton"
                  onClick={() => {
                    goMessage(items[0].username);
                  }}
                >
                  Message Seller For Details
                </button>
              </>
            )}
          </div>
        </div>
      )}

      <div className="ProfileFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel2">{copyrightLabel}</p>
      </div>

      <BuyDialog imageArray={imageSrcs} isOpen={isOpen} setIsOpen={setIsOpen} />
      <CommentDialog
        itemComments={comments}
        isOpen={isCommentOpen}
        setIsOpen={setIsCommentOpen}
      />
      <BuyChoiceDialog
        isOpen={isBuyOpen}
        setIsOpen={setBuyOpen}
        price={price}
        itemData={itemData}
      />

      {clientSecret !== "" && isCardOpen && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutFormBuy
            price={priceSelected}
            isOpen={isCardOpen}
            setIsOpen={setIsCardOpen}
            unique_id={unique_id}
            orders={items}
          />
        </Elements>
      )}
    </div>
  );
}

export default ItemPage;
