/** @format */
import React, { useEffect, useState } from "react";

import { GoogleLogin } from "react-google-login";
import { useNavigate } from "react-router-dom";
import { gapi } from "gapi-script";
import CryptoJS from 'crypto-js';

import "./../style/login.css";
import "./../style/footer.css";

import { emailCheck } from "./../data/checkEmail";

import {
  app_icon,
  app_icon2,
  apple_icon,
  google_icon,
  google_icon2
} from "./../extension/exportedIcons";

import {
  url_path,
  login,
  googleClientID,
  googleLoginSecretKey,
  copyrightLabel,
} from "./../extension/exportedStrings";

import FooterComponent from "./Footer.js";
import { addDetailFunction, getDetailCount } from "../firebase/detailCheckFirebase.js";
import { getErrorCount } from "../firebase/errorCheckFirebase.js";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "SociRank - Login"; // Change the title here

    window.gapi.load('client:auth2', () => {
      window.gapi.client
        .init({
          clientId: googleClientID,
          scope: 'profile email', // Specify the scopes you need
        })
    });

    addDetailFunction("website", "visited", "web was visited");
    // getDetailCount("website");
    // getErrorCount('register_detail');
    // getErrorCount('register_creation');
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [button, setButton] = useState("Log in");
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    setButton("Processing...")
    const username = document.getElementById("username").value.trim();
    const password = document.getElementById("password").value;

    setButtonDisabled(true);

    const url = url_path + login;
    const data = {
      username: username, // Replace with the actual username
      password: password, // Replace with the actual password
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        // Handle the response data here
        if (data == "success") {
          // Assuming you have received authentication data like a token or username
          const authenticationData = {
            username: username,
          };

          // Save the data to localStorage
          localStorage.setItem(
            "currentUser",
            JSON.stringify(authenticationData)
          );

          if (window.location.pathname === '/login') {
            window.location.href = '/';
          }else{
            window.location.reload();
            window.history.replaceState({}, '', '/login');
          }
        } else {
          // do sumn about it later
          const secretKey = googleLoginSecretKey;

          // Encrypt the data with AES encryption
          const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();
  
          const base64EncodedData = btoa(encryptedData);

          // let path = `/login/${base64EncodedData}`;
          // navigate(path);
        }
      })
      .catch((error) => {
        setButton("Server down")
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  const goRegister = () => {
    let path = `/register`;
    navigate(path);
  };

  const goForgot = () => {
    let path = `/forgot-password`;
    navigate(path);
  };

  const goApply = () => {
    let path = `/application`;
    navigate(path);
  };
  
  const signInWithGoogle = async () => {
    try {
      const auth2 = window.gapi.auth2.getAuthInstance();
      await auth2.signIn();

      // Handle the signed-in user here
      const email = auth2.currentUser.get().getBasicProfile().getEmail();
      const result = await emailCheck(email);
      if(result == "success"){
        const fullname = auth2.currentUser.get().getBasicProfile().getName();
        const data = `${email} ${fullname}`;

        const secretKey = googleLoginSecretKey;

        // Encrypt the data with AES encryption
        const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();

        const base64EncodedData = btoa(encryptedData);

        let path = `/create-account/${base64EncodedData}`;
        navigate(path);
      }else{
        // let path = `/register`;
        // navigate(path);
        const authenticationData = {
          username: result,
        };
  
        // Save the data to localStorage
        localStorage.setItem(
          "currentUser",
          JSON.stringify(authenticationData)
        );

        if (window.location.pathname === '/login') {
          window.location.href = '/';
        }else{
          window.location.reload();
          window.history.replaceState({}, '', '/login');
        }
      }
    } catch (error) {
      // Handle sign-in errors
  
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Handle the Enter key press here
      handleSubmit();
    }
  };

  return (
    <div>
      <div className="loginBody">
        <div className="introBox">
          <img src={app_icon} className="introImage" width="25" height="25" />
          <h1 className="introLabel">SociRank</h1>
          <p className="introExplain">
          Showcase your talents, earn in-app currency, and engage in a reel-like marketplace where you can sell, purchase, and thrive in a competitive social environment.
          </p>

          <a
            href="https://play.google.com/store/apps/details?id=com.socirank"
            target="_blank"
          >
            <img
              src={google_icon}
              className="google_promo"
              alt="Google promo"
            />
          </a>

          <a
            href="https://apps.apple.com/us/app/socirank/id1628275604"
            target="_blank"
          >
            <img src={apple_icon} className="apple_promo" alt="Apple promo" />
          </a>
        </div>

        <div className="loginInput">
          <div className="loginBox">
            <img src={app_icon2} className="imageBox" width="25" height="25" />
            <input
              id="username"
              type="username"
              name="username"
              placeholder="Enter username or email"
              onKeyPress={handleKeyPress}
              autoFocus
            ></input>
            <div class="password-container">
              <input
                id="password"
                class="loginPassword"
                name="password"
                type="password"
                placeholder="Enter password"
                onKeyPress={handleKeyPress}
              />
              <svg id="toggleEye" fill="currentColor" class="hidden">
                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
              </svg>
            </div>
            <p id="loginForgot" class="loginForgot" onClick={goForgot}>
              Forgot password?
            </p>
            <button
              id="loginButton"
              class="loginButton"
              disabled={isButtonDisabled}
              onClick={handleSubmit}
            >
              {button}
            </button>
            <p id="loginRegister" class="loginRegister" onClick={goRegister}>
              Create an account
            </p>

            <hr className="lineView" />
            <button
              id="gSignInWrapper"
              className="loginGoogle"
              value="submit"
              onClick={signInWithGoogle}
            >
              <img
                src={google_icon2}
                className="googleImage"
                alt="Google Icon"
              />
              Continue with Google
            </button>
          </div>

          <div className="applyDiv">
            <p class="applyText">
                We're looking to expand our team!
            </p>
            <button className="applyButton" onClick={goApply}>
              Apply now
            </button>
          </div> 
          
        </div>
      </div>
      <div className="loginFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel">
            {copyrightLabel}
          </p>
      </div>
    </div>
  );
};

export default Login;
