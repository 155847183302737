// process.env.REACT_APP_HEROKU_URL
// export const url_path = process.env.HEROKU_URL || "http://localhost:5000/";
export const url_path = "";
export const googleClientID = process.env.REACT_APP_CLIENTID || "827120467315-p9spgrtl1kkmi1bqgit8eiufpn7lk25r.apps.googleusercontent.com";

export const login = "https://login-rpu55a24ja-uc.a.run.app" || "login";
export const register = "https://register-rpu55a24ja-uc.a.run.app" || "register";
export const check = "https://detailcheck-rpu55a24ja-uc.a.run.app" || "detailCheck";
export const config = "https://getuser-rpu55a24ja-uc.a.run.app" || "user";
export const configSearch = "https://getusers-rpu55a24ja-uc.a.run.app" || "users";
export const emailPath = "https://application-mail-rpu55a24ja-uc.a.run.app" || "application-mail";
export const resetPath = "https://change-password-rpu55a24ja-uc.a.run.app" || "change-password";
export const deletePath = "https://delete-rpu55a24ja-uc.a.run.app" || "delete";
export const paymentIntentPath = "https://create-payment-intent-rpu55a24ja-uc.a.run.app" || "create-payment-intent";
export const savePath = "https://store-payment-intent-rpu55a24ja-uc.a.run.app" || "store-payment-intent";
export const paymentDetailsPath = "https://payment-details-rpu55a24ja-uc.a.run.app" || "payment-details";
export const forgotPassword = "https://forgotpassword-rpu55a24ja-uc.a.run.app"
export const changeProfile = "https://change-profile-rpu55a24ja-uc.a.run.app"
export const changeBio = "https://update-bio-rpu55a24ja-uc.a.run.app"
// coin
export const getCoins = "https://socicoin-rpu55a24ja-uc.a.run.app" || "socicoin";
export const purchaseWithCoins = "https://purchase-with-socicoin-rpu55a24ja-uc.a.run.app"
export const socicoin_purchase = "https://socicoin-purchase-rpu55a24ja-uc.a.run.app"

//location
export const exchangeRateAPI = "7f158bc354d684eb8e3344ef"
export const getCountry = "https://getcountry-rpu55a24ja-uc.a.run.app"

// ad
export const getWatchAD = "https://getwatchad-rpu55a24ja-uc.a.run.app" || "getWatchAD";
export const retrieveWatchAD = "https://retrievewatchad-rpu55a24ja-uc.a.run.app" || "retrieveWatchAD";

// invite
export const inviteLink = "https://invite-link-rpu55a24ja-uc.a.run.app" || "invite-link";
export const confirmInvite = "https://confirm-invite-rpu55a24ja-uc.a.run.app" || "confirm-invite";

// transaction
export const createItem = "https://createitem-rpu55a24ja-uc.a.run.app" || "create-item"
export const retrieveItem = "https://retrieveitem-rpu55a24ja-uc.a.run.app"
export const updateItem = "https://updateitem-rpu55a24ja-uc.a.run.app"
export const retrieveOneItem = "https://retrieveoneitem-rpu55a24ja-uc.a.run.app"
export const deleteItem = "https://deleteitem-rpu55a24ja-uc.a.run.app"

export const createOrder = "https://createorder-rpu55a24ja-uc.a.run.app"
export const retrieveOrder = "https://retrieveorder-rpu55a24ja-uc.a.run.app"
export const retrieveSales = "https://retrievesales-rpu55a24ja-uc.a.run.app"
export const updateOrder = "https://updateorder-rpu55a24ja-uc.a.run.app"
export const updateStatus = "https://updateorderstatus-rpu55a24ja-uc.a.run.app"
export const deleteOrder = "https://deleteorder-rpu55a24ja-uc.a.run.app"
export const adminOrder = "https://adminorder-rpu55a24ja-uc.a.run.app"
export const createSaleConfirmation = "https://createsaleconfirmation-rpu55a24ja-uc.a.run.app"
export const retrieveSaleConfirmation = "https://retrievesaleconfirmation-rpu55a24ja-uc.a.run.app"

// transaction
export const createDigital = "https://createdigital-rpu55a24ja-uc.a.run.app" || "create-item"
export const retrieveItemDigital = "https://retrieveitemdigital-rpu55a24ja-uc.a.run.app"
export const updateItemDigital = "https://updateitemdigital-rpu55a24ja-uc.a.run.app"
export const retrieveOneItemDigital = "https://retrieveoneitemdigital-rpu55a24ja-uc.a.run.app"
export const deleteItemDigital = "https://deleteitemdigital-rpu55a24ja-uc.a.run.app"


export const itemImage = "https://itemimage-rpu55a24ja-uc.a.run.app"
export const digitalAudio = "https://digitalaudio-rpu55a24ja-uc.a.run.app"
export const digitalImage = "https://digitalimage-rpu55a24ja-uc.a.run.app"
export const digitalVideoToAudio = "https://digitalvideotoaudio-rpu55a24ja-uc.a.run.app"
export const createPersonalDigital = "https://createpersonaldigital-rpu55a24ja-uc.a.run.app"
export const updateDigitalStatus = "https://updatedigitalstatus-rpu55a24ja-uc.a.run.app"

export const retrieveAllitemsAndDigitals = "https://retrieveallitemanddigital-rpu55a24ja-uc.a.run.app"
export const retrieveItemsAndDigitals = "https://itemsanddigitals-rpu55a24ja-uc.a.run.app"
export const retrieveCategory = "https://retrievecategoryitem-rpu55a24ja-uc.a.run.app"

export const insertComment = "https://insertcomment-rpu55a24ja-uc.a.run.app"
export const retrieveComment = "https://retrievecomment-rpu55a24ja-uc.a.run.app"
export const deleteComment = "https://deletecomment-rpu55a24ja-uc.a.run.app"
export const retrievePersonalComment = "https://retrievepersonalcomment-rpu55a24ja-uc.a.run.app"
// address
export const insertAddress = "https://updateaddress-rpu55a24ja-uc.a.run.app"
export const retrieveAddress = "https://retrieveaddress-rpu55a24ja-uc.a.run.app"

// notification
export const createItemNotification = "https://itemnotification-rpu55a24ja-uc.a.run.app"
export const retrieveItemNotification = "https://retrieveitemnotification-rpu55a24ja-uc.a.run.app"
export const seenItemNotification = "https://checkseenitemnotification-rpu55a24ja-uc.a.run.app"

//message 
export const sendMessage = "https://sendmessage-rpu55a24ja-uc.a.run.app"
export const retrieveMessage = "https://receivemessage-rpu55a24ja-uc.a.run.app"
export const messageList = "https://messagelist-rpu55a24ja-uc.a.run.app"
export const delete_unread_count = "https://delete-unread-rpu55a24ja-uc.a.run.app";

export const stripePublishableKey = process.env.STRIPE_PUBLISHABLE_KEY || "pk_live_51M5o3IGWslu84LeouTM9BYyN67OgYHCPPsFswIAXet4LAs1TedaeNgoubBwfJQAt6TGz3jMRSFtHbKFmB73nBjoo00tQbCIP6N";
// export const stripePublishableKey = process.env.STRIPE_PUBLISHABLE_KEY || "pk_test_51M5o3IGWslu84Leou5Mvu4ISGxHaLIUAabIEDKs6m1x0MhVoMzQgiTj5G5OjLMoX5RT4vTuRzGghsqhrzcZbLErh00rF9gztkO";

// export const stripeTestKey = process.env.STRIPE_PUBLISHABLE_KEY || "pk_live_51M5o3IGWslu84LeouTM9BYyN67OgYHCPPsFswIAXet4LAs1TedaeNgoubBwfJQAt6TGz3jMRSFtHbKFmB73nBjoo00tQbCIP6N";
// export const stripeClientKey = "pk_test_51M5o3IGWslu84Leou5Mvu4ISGxHaLIUAabIEDKs6m1x0MhVoMzQgiTj5G5OjLMoX5RT4vTuRzGghsqhrzcZbLErh00rF9gztkO";

export const purchase_validity = "https://purchase-validity-rpu55a24ja-uc.a.run.app"
export const purchase_key = "MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQC1w6zJMUCpaIXHFY4q4XxwDXGnEoNeMEi0QhsB3Q2ZngT3Afq8+n1cBhSmJXvrHNIo9OcaqUIYXgFf"
export const url_link = "https://socirankfiles.xyz/projectweb/mobileconnect/";

export const websiteLink = "https://socirank.com/invite/"
// export const websiteLink = "http://localhost:3000/invite/"
export const mainLink = "https://socirank.com/";
export const websiteName = "SociRank";
export const googleLoginSecretKey = "googleLoginSecret123321"

export const copyrightLabel = "SociRank © 2023"

